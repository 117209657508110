import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import parse from "html-react-parser";
import SecondaryHero from "../../components/secondary-hero";
import EventSection from "../../components/event-section";

const EquipmentAmenitiesServicesPage = ({ transitionStatus }) => {
	const data = useStaticQuery(graphql`
		query {
			Img1: wpMediaItem(title: { eq: "EAS Image 1" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			Img2: wpMediaItem(title: { eq: "EAS Image 2" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			Img3: wpMediaItem(title: { eq: "EAS Image 3" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			Img4: wpMediaItem(title: { eq: "EAS Image 4" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			Img5: wpMediaItem(title: { eq: "EAS Image 5" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			Img6: wpMediaItem(title: { eq: "EAS Image 6" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			Img7: wpMediaItem(title: { eq: "EAS Image 7" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			Img8: wpMediaItem(title: { eq: "EAS Image 8" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			Img9: wpMediaItem(title: { eq: "EAS Image 9" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			wpPageContent(id: { eq: "cG9zdDo1MTM=" }) {
				content
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Fundraising",
				item: {
					url: `${siteUrl}/fundraising`,
					id: `${siteUrl}/fundraising`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: "Equipment Amenities Services",
				item: {
					url: `${siteUrl}/fundraising/equipment-amenities-services`,
					id: `${siteUrl}/fundraising/equipment-amenities-services`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title="Equipment Amenities Services | Friends of Eastbourne Hospital"
				description="The staff, wards and departments of the DGH are invited to approach the Friends with bids for any assistance, from equipment to education, that we may be able to provide."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/fundraising/equipment-amenities-services`,
					title:
						"Equipment Amenities Services | Friends of Eastbourne Hospital",
					description:
						"The staff, wards and departments of the DGH are invited to approach the Friends with bids for any assistance, from equipment to education, that we may be able to provide.",
					images: [
						{
							url: `${data.Img9?.localFile.publicURL}`,
							width: `${data.Img9?.localFile.childImageSharp.original.width}`,
							height: `${data.Img9?.localFile.childImageSharp.original.height}`,
							alt: `${data.Img9?.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<section className="pb-5">
					<SecondaryHero
						title="Equipment | Amenities | Services"
						description={
							<span>
								The staff, wards and departments of the DGH are invited to
								approach <br className="d-none d-md-block" /> the Friends with
								bids for any assistance, from equipment to education,{" "}
								<br className="d-none d-md-block" /> that we may be able to
								provide.
							</span>
						}
					/>
				</section>
				<section className="pb-md-7 pb-5">
					<Container>
						<Row>
							<Col className="page-content e-a-s">
								{parse(data.wpPageContent.content)}
							</Col>
						</Row>
					</Container>
				</section>
				<section>
					<Container fluid className="px-0 mx-0 object-cover">
						<Row className="g-0">
							<Col xs={12} lg={5}>
								<GatsbyImage
									image={data.Img1.localFile.childImageSharp.gatsbyImageData}
									alt=""
									className="h-100"
								/>
							</Col>
							<Col xs={12} lg={true}>
								<GatsbyImage
									image={data.Img2.localFile.childImageSharp.gatsbyImageData}
									alt=""
									className="h-100"
								/>
							</Col>
							<Col xs={12} lg={true}>
								<GatsbyImage
									image={data.Img3.localFile.childImageSharp.gatsbyImageData}
									alt=""
									className="h-100"
								/>
							</Col>
						</Row>
						<Row className="g-0">
							<Col xs={12} lg={true}>
								<GatsbyImage
									image={data.Img4.localFile.childImageSharp.gatsbyImageData}
									alt=""
									className="h-100"
								/>
							</Col>
							<Col xs={12} lg={true}>
								<GatsbyImage
									image={data.Img5.localFile.childImageSharp.gatsbyImageData}
									alt=""
									className="h-100"
								/>
							</Col>
							<Col xs={12} lg={5}>
								<GatsbyImage
									image={data.Img6.localFile.childImageSharp.gatsbyImageData}
									alt=""
									className="h-100"
								/>
							</Col>
						</Row>
						<Row className="g-0">
							<Col xs={12} lg={3}>
								<GatsbyImage
									image={data.Img7.localFile.childImageSharp.gatsbyImageData}
									alt=""
									className="h-100"
								/>
							</Col>
							<Col xs={12} lg={true}>
								<GatsbyImage
									image={data.Img8.localFile.childImageSharp.gatsbyImageData}
									alt=""
									className="h-100"
								/>
							</Col>
							<Col xs={12} lg={5}>
								<GatsbyImage
									image={data.Img9.localFile.childImageSharp.gatsbyImageData}
									alt=""
									className="h-100"
								/>
							</Col>
						</Row>
					</Container>
				</section>
				<section>
					<EventSection />
				</section>
			</Layout>
		</>
	);
};

export default EquipmentAmenitiesServicesPage;
